.img {
    display: block;
    margin-bottom: 20px;
    width: 80%;
    object-fit: cover;
    margin-left: 10%;
    margin-right: 25%;
    z-index: -1;
}

.topInput {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
}

.leftInput {
    margin-left: 1px;
    margin-right: 25px;
    padding: 10px;
    margin-bottom: 10px;
}

.rightInput {
    margin-right: 0;
    margin-left: 25px;
    padding: 10px;
    margin-bottom: 10px;
}

.bottomInput {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
}

.textInputs {
    margin-top: 20px;
    margin-bottom: 20px;
}

.surfaceInputs {
    position: relative;
    text-align: center;
}

.surfaceInputs>div {
    font-family: 'Open Sans', sans-serif;
    color: rgb(245, 159, 0);
    font-size: 25px;
}

/* @Media (min-width:308px) and (max-width:352px) {
    .surfaceInputs > .surfaceTop {
        text-align: center;
        width: 80%;
        position: absolute;
        top: 0%;
        left: 11%;
    }

    .surfaceLeft {
        text-align: center;
        position: absolute;
        width: 13%;
        word-wrap: normal;
        top: 50%;
        left: 10%;
    }

    .surfaceRight {
        text-align: center;
        position: absolute;
        width: 13%;
        word-wrap: normal;
        top: 50%;
        right: 10%;
    }

    .surfaceBottom {
        text-align: center;
        position: absolute;
        width: 80%;
        bottom: 0%;
        left: 11%;
    }
} */

@Media (min-width: 150px) {

    .surfaceInputs>.surfaceTop {
        text-align: center;
        width: 80%;
        position: absolute;
        top: 0%;
        left: 11%;
    }

    .surfaceLeft {
        text-align: center;
        position: absolute;
        width: 13%;
        word-wrap: normal;
        top: 50%;
        left: 10%;
    }

    .surfaceRight {
        text-align: center;
        position: absolute;
        width: 13%;
        word-wrap: normal;
        top: 50%;
        right: 10%;
    }

    .surfaceBottom {
        text-align: center;
        position: absolute;
        width: 80%;
        bottom: 0%;
        left: 11%;
    }

    .navItemOne:hover {
        background-color: red;
        cursor: pointer;
        color: rgb(146, 134, 134);
    }

    .navItemOne .nav-link {
        color: rgb(146, 134, 134);
    }

    .card-body {
        margin-top: 2%;
    }

    .randomMemeBtn {
        background-color: rgb(245, 63, 63) !important;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
    }

    .importingImages {
        margin-top: 3%;
        margin-bottom: 1%;
    }

    .imageUpload {
        text-align: left;
        border: none;
    }

    .downloadBtnDiv {
        text-align: center;
        padding-top: 1%;
        padding-bottom: 3%;
    }

    .downloadBtnDiv .downloadBtn {
        background-color: rgb(245, 63, 63) !important;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
        border: none;
        border-radius: 10px;
        padding: 10px;
        width: 40%;

    }
}