.createGIF {
    margin-top: 30px;
    margin-left: 20px;
}

.createGIFBtn {
    background-color: red;
    color: whitesmoke;
    text-align: center;
    border: none;
    border-radius: 20px;
    padding: 10px;
    font-weight: bold;
}

span{
    display: block;
    margin-top: 10px;
    text-align: center;
    color: rgb(243, 81, 81);
    font-size: 10px;
}