.footer{
    background-color: black;
    color: rgb(248, 241, 241);
    text-align: center;
    padding-top: 8px; 
    padding-bottom: 1px;
}

.footer >h5{
    margin-top: 10px;
}
.gnu{
    font-size: 13px;
    color:whitesmoke;
}