.header {
    position: sticky ;
    top: 0;
    z-index: 1;
}

.brand{
    color: red;
}

.logo{
    width: 60px;
    height: 40px;
    margin-left: 7px;
    object-fit: cover;
}

.me-auto{
    margin-left: 2px;
}

#collasible-nav-dropdown:hover{
    background-color: red;
}